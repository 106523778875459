import api from '../../../services/api/api';
import message from '../../../utils/message';
import {
  PreventiveMaintenanceType,
  PreventiveMaintenanceVehicle,
} from '../../workshop/PreventiveMaintenance/interface';

export interface User {
  id_user: string;
  is_first_access: boolean;
  is_first_access_after_recovery: boolean;
  name: string;
  is_locator: boolean;
  surname: null | string;
  email: null | string;
  phone: string;
  contact_id: null | string;
  avatar: null | string;
  role_name: string;
  document: string;
  address_id: null | string;
  created_at: Date;
  updated_at: Date;
}

export interface NotificationProps {
  id_notification: string;
  message: string;
  read: boolean;
  type: string;
  workshop_id: string;
  repair_id: string | null;
  user_id: string;
  preventive_id: string | null;
  created_at: Date;
  updated_at: Date;
  user: User;
  preventive: NotificationPreventive | null;
}

export interface NotificationPreventive {
  id_preventive: string;
  car_total_km_active: number;
  car_km_to_active: number;
  days_to_active: number;
  active_at: Date;
  actived: boolean;
  sent_before_date: boolean;
  sent_on_date: boolean;
  sent_after_date: boolean;
  type_name: string;
  type_id: string;
  vehicle_id: string;
  workshop_id: string;
  created_at: Date;
  updated_at: Date;
  type?: PreventiveMaintenanceType;
  vehicle: PreventiveMaintenanceVehicle;
}

export const getNotifications = async () => {
  const { data } = await api.get<NotificationProps[]>('/notification');

  return data;
};

export const deleteNotification = async (notification_id: string) => {
  try {
    await api.delete(`/notification/${notification_id}`);
    return true;
  } catch (error) {
    message(error, 'error');
    return false;
  }
};

export const setNotificationAsRead = async (notification_id: string) => {
  try {
    await api.put(`/notification/read?id_notification=${notification_id}`);
    return true;
  } catch (error) {
    message(error, 'error');
    return false;
  }
};
