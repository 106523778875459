import { Route, Routes as Switch } from 'react-router-dom';

import { BudgetReport } from '../../utils/pdf/Budget';
import { OrderServiceReport } from '../../utils/pdf/OrderService';
import { ChecklistReport } from '../../utils/pdf/ChecklistReport';

export const ReportRoutes = () => (
  <Switch>
    <Route path="/budget-report" element={<BudgetReport />} />
    <Route path="/budgets" element={<BudgetReport />} />
    <Route path="/order-service-report" element={<OrderServiceReport />} />
    <Route path="/transaction-report" element={<OrderServiceReport />} />
    <Route path="/checklist-report" element={<ChecklistReport />} />
  </Switch>
);
