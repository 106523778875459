/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  ArrowClockwise,
  CaretDown,
  CaretUp,
  Eye,
  Users,
  X,
} from 'phosphor-react';
import { useCallback, useMemo } from 'react';
import { Button } from '../../../../../../../../../shared/Button';
import { Table } from '../../../../../../../Finance/components/Table';
import styles from './desktop-list.module.css';
import { Quotation } from '../../../../../../../../../@types/interface';
import {
  dateOffset,
  formatDate,
  formatTime,
} from '../../../../../../../../../utils/dateFunctions';
import useViewModal from '../../../../../hooks/useViewModal';
import useSelectedQuotation from '../../../../../hooks/useSelectedQuotation';
import useAddCompanyModal from '../../../../../hooks/useAddCompanyModal';
import useRedoModal from '../../../../../hooks/useRedoModal';
import useFinishModal from '../../../../../hooks/useFinishModal';
import useInfiniteScroll from '../../../../../../../../../hooks/useInfinityScroll';
import { ListLoader } from '../../../../../../../../../shared/ListLoader';
import { Circle } from '../../../../../../../../../shared/Circle';
import { useResponsive } from '../../../../../../../../../hooks/useResponsive';

interface Props {
  quotationList: Quotation[];
  handleRowClick: (quotation: Quotation) => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
}

export const DesktopList = ({
  quotationList,
  handleRowClick,
  onScroll,
  isLoading,
  isEmpty = false,
  notFound = false,
}: Props) => {
  const viewModal = useViewModal();
  const selectedQuotation = useSelectedQuotation();
  const addCompanyModal = useAddCompanyModal();
  const redoModal = useRedoModal();
  const finishModal = useFinishModal();

  const listRef = useInfiniteScroll(onScroll);

  const message = useMemo(() => {
    if (isEmpty) return 'Sem Novas Cotações';
    if (notFound) return 'Nenhuma Cotação Encontrada';
    return null;
  }, [isEmpty, notFound]);

  return (
    <Table.Root>
      <Table.Row className={styles['table-header']}>
        <Table.Header value="N° Cotação" align="left" />
        <Table.Header value="Data de Criação" align="left" />
        <Table.Header value="Placa" />
      </Table.Row>

      <div ref={listRef} className={styles['table-content']}>
        {message && <p>{message}</p>}
        {!message &&
          quotationList.map(quotationItem => (
            <ListItem
              key={quotationItem.id_quotation}
              quotation={quotationItem}
              isSelected={
                !!selectedQuotation.quotation &&
                selectedQuotation.quotation.id_quotation ===
                  quotationItem.id_quotation
              }
              onAddAutoshop={() => addCompanyModal.onOpen()}
              onFinish={() => finishModal.onOpen()}
              onRedo={() => redoModal.onConfirmRedoOpen()}
              onRowClick={() => handleRowClick(quotationItem)}
              onView={() => viewModal.onOpen()}
            />
          ))}
        {!message && isLoading && <ListLoader />}
      </div>
    </Table.Root>
  );
};

interface ListItemProps {
  quotation: Quotation;
  onView: () => void;
  onFinish: () => void;
  onRedo: () => void;
  onRowClick: (quotation: Quotation) => void;
  onAddAutoshop: () => void;
  isSelected: boolean;
}

const ListItem = ({
  quotation,
  isSelected,
  onAddAutoshop,
  onFinish,
  onRedo,
  onRowClick,
  onView,
}: ListItemProps) => {
  // const situation = useMemo(() => {
  //   if (quotation?.finished) {
  //     return { color: '', label: 'Cotação Finalizada}' };
  //   }
  //   if (quotation?.new_offers) {
  //     return { color: '', label: 'Nova Oferta' };
  //   }

  //   return { color: '', label: 'Aguardando Aprovação' };
  // }, [quotation.finished, quotation.new_offers]);

  const createdAt = useMemo(() => {
    if (!quotation?.created_at) return '--/--/--';
    const date = dateOffset(new Date(quotation.created_at));

    return `${formatDate(date)} às ${formatTime(date)}`;
  }, [quotation.created_at]);

  // const numberOfAnswers = useMemo(() => {

  // }, [])
  const licensePlate = useMemo(() => {
    if (!quotation.vehicle) return '---';
    return quotation.vehicle.license_plate;
  }, [quotation.vehicle]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onRowClick(quotation);
      e.stopPropagation();
    },
    [onRowClick, quotation],
  );

  // const visualizedStatus = useMemo(() => {
  //   if (quotation.visualized) return 'default';
  //   return 'notification';
  // }, [quotation.visualized]);

  const RowIcon = useMemo(
    () => (isSelected ? CaretUp : CaretDown),
    [isSelected],
  );

  const { sizeMobile } = useResponsive();

  return (
    <div>
      <Table.Row
        className={`${styles['table-row']} ${
          isSelected ? styles.selected : ''
        }`}
        onClick={handleClick}
      >
        <Table.Cell alignStart>
          <div className={styles['first-line']}>
            <RowIcon size={20} />
            {quotation?.QUOTATION ?? ''}
            {/* <Circle variant={visualizedStatus} /> */}
          </div>
        </Table.Cell>
        <Table.Cell alignStart={!sizeMobile}>{createdAt}</Table.Cell>
        <Table.Cell>{licensePlate}</Table.Cell>
      </Table.Row>
      {isSelected && (
        <Table.Row className={styles['row-actions']}>
          <Button handleClick={onView}>
            <Eye size={20} weight="bold" />
            Visualizar
          </Button>
          <Button handleClick={onAddAutoshop}>
            <Users size={20} weight="bold" />
            Adicionar Fornecedor
          </Button>
          <Button handleClick={onRedo} style={{ background: 'var(--b_grey)' }}>
            <ArrowClockwise
              size={20}
              style={{
                transform: 'rotateY(180deg)',
              }}
            />
            Refazer
          </Button>
          <Button handleClick={onFinish} variant="cancel">
            <X size={20} weight="bold" />
            Finalizar
          </Button>
        </Table.Row>
      )}
    </div>
  );
};
