import { Controller } from 'react-hook-form';
import {
  forwardRef,
  InputHTMLAttributes,
  SelectHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { FloppyDiskBack } from 'phosphor-react';
import { PageLayout } from '../../../shared/PageLayout';
import { useRegisterForms } from './hooks/useRegisterForms';
import styles from './register.module.css';
import {
  maskCpfCnpj,
  maskPhone,
  numberOnly,
} from '../../../services/helpers/mask';
import { useRegisterData } from './hooks/useRegisterData';
import { FormValues } from './interface';
import { getUserData } from '../../../utils/workshopData';

interface InputComponentProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  hint?: string;
}
interface SelectComponentProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  options: {
    name: string;
    value: string;
  }[];
}

const InputComponent = forwardRef<HTMLDivElement, InputComponentProps>(
  ({ label, hint, ...rest }, ref) => {
    return (
      <div
        className={`${styles['input-controller']} ${hint ? styles.error : ''}`}
        ref={ref}
      >
        <span>{label}</span>
        <input {...rest} />
        {hint && <i>{hint}</i>}
      </div>
    );
  },
);
const SelectComponent = forwardRef<HTMLDivElement, SelectComponentProps>(
  ({ label, options, ...rest }, ref) => {
    return (
      <div className={styles['input-controller']} ref={ref}>
        <span>{label}</span>
        <select {...rest}>
          {options &&
            options.map(option => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
        </select>
      </div>
    );
  },
);

export const Register = () => {
  const {
    control,
    reset,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useRegisterForms();
  const {
    fetchApiWorkshop,
    fetchAddressByZipCode,
    updateWorkshopData,
    updateWorkshopPassword,
    // updateCode,
  } = useRegisterData();

  useEffect(() => {
    fetchApiWorkshop(reset);
  }, []);

  const userData = getUserData();
  const userType = () => {
    if (userData) {
      if (userData.role_name === 'gas_station') return 'do Posto';
      if (userData.role_name === 'fleet') return 'da Frota';
    }
    return 'da Oficina';
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleAddress = (zipCode: string) => {
    const previousFormValues = getValues();
    fetchAddressByZipCode(zipCode, reset, previousFormValues);
  };

  const handleSaveData = async (formValues: FormValues) => {
    await updateWorkshopData(formValues);
    if (
      formValues.password.trim().length > 0 ||
      formValues.confirmPassword.trim().length > 0
    ) {
      await updateWorkshopPassword(formValues);
    }
    // if (formValues.code && formValues.code.trim().length > 0) {
    //   await updateCode(formValues);
    // }
  };

  return (
    <PageLayout.Root title="Meu Cadastro">
      <form
        className={styles.container}
        onSubmit={handleSubmit(handleSaveData)}
      >
        <section className={styles.section}>
          <h1>Dados {userType()}</h1>
          <div className={styles.row}>
            <Controller
              control={control}
              name="corporateName"
              render={({ field }) => {
                return (
                  <InputComponent
                    label="Razão Social"
                    hint={
                      errors.corporateName ? errors.corporateName.message : ''
                    }
                    {...field}
                    disabled
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="fantasyName"
              render={({ field }) => {
                return (
                  <InputComponent
                    label="Nome Fantasia"
                    hint={errors.fantasyName ? errors.fantasyName.message : ''}
                    {...field}
                  />
                );
              }}
            />
          </div>
          <div className={styles.row}>
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, ...rest } }) => {
                return (
                  <InputComponent
                    label="Telefone"
                    {...rest}
                    onChange={e => onChange(maskPhone(e.target.value))}
                    maxLength={15}
                    hint={errors.phone ? errors.phone.message : ''}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="whatsapp"
              render={({ field: { onChange, ...rest } }) => {
                return (
                  <InputComponent
                    label="Whatsapp"
                    {...rest}
                    onChange={e => onChange(maskPhone(e.target.value))}
                    maxLength={15}
                    hint={errors.whatsapp ? errors.whatsapp.message : ''}
                  />
                );
              }}
            />
          </div>
          <div className={styles.row}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => {
                return (
                  <InputComponent
                    label="E-mail"
                    {...field}
                    hint={errors.email ? errors.email.message : ''}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="document"
              render={({ field: { onChange, ...rest } }) => {
                return (
                  <InputComponent
                    label="CPF/CNPJ"
                    {...rest}
                    onChange={e => onChange(maskCpfCnpj(e.target.value))}
                    disabled
                    hint={errors.document ? errors.document.message : ''}
                  />
                );
              }}
            />
          </div>
          <div className={styles.row}>
            <Controller
              control={control}
              name="stateRegistration"
              render={({ field }) => {
                return <InputComponent label="Inscrição Estadual" {...field} />;
              }}
            />

            <Controller
              control={control}
              name="type"
              render={({ field }) => {
                return (
                  <SelectComponent
                    label="Tipo de Oficina no Mapa"
                    options={[
                      {
                        value: 'CAR',
                        name: 'Carro',
                      },
                      {
                        value: 'MOTORCYCLE',
                        name: 'Moto',
                      },
                      {
                        value: 'TRUCK',
                        name: 'Caminhão',
                      },
                      {
                        value: 'ALL',
                        name: 'Carro/ Caminhão/ Moto',
                      },
                    ]}
                    {...field}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="quotation_segment"
              render={({ field }) => {
                return (
                  <SelectComponent
                    label="Participação na Cotação"
                    options={[
                      {
                        value: 'seller',
                        name: 'Vendedor',
                      },
                      {
                        value: 'buyer',
                        name: 'Comprador',
                      },
                      {
                        value: 'both',
                        name: 'Ambos',
                      },
                    ]}
                    {...field}
                  />
                );
              }}
            />
          </div>
        </section>

        <section className={styles.section}>
          <h1>Endereço</h1>
          <div className={styles.row}>
            <Controller
              control={control}
              name="address.zip"
              render={({ field: { onChange, ...rest } }) => {
                return (
                  <InputComponent
                    label="CEP"
                    {...rest}
                    onChange={e => {
                      const newValue = numberOnly(e.target.value);
                      onChange(newValue);
                      if (newValue.length >= 8) handleAddress(newValue);
                    }}
                    maxLength={9}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="address.street"
              render={({ field }) => {
                return <InputComponent label="Endereço" {...field} />;
              }}
            />
            <Controller
              control={control}
              name="address.number"
              render={({ field }) => {
                return <InputComponent label="Número" {...field} />;
              }}
            />
          </div>
          <div className={styles.row}>
            <Controller
              control={control}
              name="address.district"
              render={({ field }) => {
                return <InputComponent label="Bairro" {...field} />;
              }}
            />
            <Controller
              control={control}
              name="address.city"
              render={({ field }) => {
                return <InputComponent label="Cidade" {...field} />;
              }}
            />
            <Controller
              control={control}
              name="address.uf"
              render={({ field }) => {
                return <InputComponent label="UF" {...field} />;
              }}
            />
          </div>
        </section>

        <section className={styles.section}>
          <h1>Informações de Acesso</h1>
          <div>
            <div className={styles.row}>
              <Controller
                control={control}
                name="password"
                render={({ field }) => {
                  return (
                    <InputComponent
                      label="Nova Senha (opcional)"
                      {...field}
                      hint={errors.password ? errors.password.message : ''}
                      type={showPassword ? 'text' : 'password'}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="confirmPassword"
                render={({ field }) => {
                  return (
                    <InputComponent
                      label="Confirmar Senha"
                      {...field}
                      hint={
                        errors.confirmPassword
                          ? errors.confirmPassword.message
                          : ''
                      }
                      type={showPassword ? 'text' : 'password'}
                    />
                  );
                }}
              />
            </div>
            <label
              htmlFor="showPassword"
              className={styles['show-password-controller']}
            >
              <input
                id="showPassword"
                type="checkbox"
                onChange={() => setShowPassword(previous => !previous)}
              />
              exibir senha
            </label>
          </div>
          {/* <div className={styles.row}>
            <Controller
              control={control}
              name="code"
              render={({ field }) => {
                return <InputComponent label="Código Promocional" {...field} />;
              }}
            />
          </div> */}
        </section>

        <button
          type="submit"
          className={styles['save-btn']}
          // onClick={handleSaveData}
        >
          <FloppyDiskBack size={32} /> Salvar
        </button>
      </form>
    </PageLayout.Root>
  );
};
