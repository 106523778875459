/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FileText, MagnifyingGlassPlus } from 'phosphor-react';
import React, { useCallback, useMemo, useState } from 'react';
import { MinusSquare, PlusSquare } from '@phosphor-icons/react';
import styles from './box-info.module.css';
import imagePlaceholder from '../../../../../../assets/img/image-placeholder.svg';
import { AnswerQuotation, Quotation } from '../../../../../../@types/interface';
import { ViewImagesModal } from '../ViewImagesModal';

interface Props {
  quotation: Quotation | null;
  answer?: AnswerQuotation | null;
}

export const BoxInfo = ({ quotation, answer }: Props) => {
  const [isClosed, setIsClosed] = useState(false);
  const [viewImages, setViewImages] = useState(false);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setIsClosed(previous => !previous);
    },
    [],
  );

  const handleClickToViewImages = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setViewImages(previous => !previous);
    },
    [],
  );

  const banners = useMemo(() => quotation?.banners || [], [quotation?.banners]);

  const quotationVehicle = useMemo(() => {
    return quotation?.vehicle ?? null;
  }, [quotation?.vehicle]);

  const paymentMethod = useMemo(() => {
    return quotation?.type_payment ?? 'Não informado';
  }, [quotation?.type_payment]);

  const quotationComment = useMemo(() => {
    return quotation?.comment ?? 'sem observações';
  }, [quotation?.comment]);

  const answerComment = useMemo(() => {
    return answer?.comment ?? 'sem comentários do fornecedor';
  }, [answer?.comment]);

  const rebuttal = useMemo(() => {
    return answer?.rebuttal ?? 'sem resposta da oficina';
  }, [answer?.rebuttal]);

  if (isClosed)
    return (
      <div
        className={styles.closed}
        onClick={handleClick}
        style={{ position: 'relative' }}
      >
        <MagnifyingGlassPlus size={32} />
        Clique para expandir as informações
        <PlusSquare size={24} color="black" className={styles.icon} />
      </div>
    );

  return (
    <>
      <ViewImagesModal
        isOpen={viewImages}
        onClose={() => setViewImages(false)}
        banners={banners}
      />
      <div className={styles.container}>
        <div onClick={handleClick} className={styles.icon}>
          <MinusSquare size={24} color="black" />
        </div>
        <div className={styles['top-wrapper']}>
          {quotationVehicle ? (
            <div className={styles['vehicle-info']}>
              <h1 className={styles['vehicle-info-name']}>
                {quotation?.vehicle?.name ?? '---'}
              </h1>
              <ul className={styles['vehicle-info-list']}>
                <li>
                  <span>Placa</span>
                  <span>{quotation?.vehicle?.license_plate ?? '---'}</span>
                </li>
                <li>
                  <span>Chassi</span>
                  <span>{quotation?.vehicle?.chassi ?? '---'}</span>
                </li>
                <li>
                  <span>Ano</span>
                  <span>{quotation?.vehicle?.year ?? '---'}</span>
                </li>
              </ul>
            </div>
          ) : (
            <div className={styles['vehicle-not-found']}>
              <FileText size={32} /> dados do veículo não informado
            </div>
          )}
          {banners && banners.length > 0 && (
            <div
              className={styles['images-wrapper']}
              onClick={e => handleClickToViewImages(e)}
            >
              <img src={banners[0].img_url || imagePlaceholder} alt="" />
              {banners.length > 1 && (
                <button type="button" onClick={e => handleClickToViewImages(e)}>
                  ver todas
                </button>
              )}
              {banners.length === 0 && <b>nenhuma imagem informada</b>}
            </div>
          )}
        </div>
        <div className={styles['observation-wrapper']}>
          <span>Forma de Pagamento</span>
          <p>{paymentMethod}</p>
        </div>
        <div className={styles['observation-wrapper']}>
          <span>Observações</span>
          <p>{quotationComment}</p>
        </div>
        {answer && (
          <>
            <div className={styles['observation-wrapper']}>
              <span>Comentário do Fornecedor</span>
              <p>{answerComment}</p>
            </div>
            <div className={styles['observation-wrapper']}>
              <span>Resposta da Oficina</span>
              <p>{rebuttal}</p>
            </div>
          </>
        )}
        {banners && banners.length > 0 && (
          <div
            className={styles['images-wrapper']}
            onClick={e => handleClickToViewImages(e)}
          >
            <img src={banners[0].img_url || imagePlaceholder} alt="" />
            {banners.length > 1 && (
              <button type="button" onClick={e => handleClickToViewImages(e)}>
                ver todas
              </button>
            )}
            {banners.length === 0 && <b>nenhuma imagem informada</b>}
          </div>
        )}
      </div>
    </>
  );
};
