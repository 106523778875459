import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './answered-quotations.module.css';
import createdQuotationsIcon from '../../../../../../assets/icons/created-quotations.svg';
import { useAnsweredQuotations } from './hooks/useAnsweredQuotations';
import { useChangeQuotationState } from '../../hooks/useChangeQuotationState';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { QuotationFilterOptions } from '../../interface';

import { FinishModal } from './components/FinishModal';
import { useQuotation } from '../../../../../../contexts/quotationContext';
import { QuotationList } from './components/QuotationListItem';
import { Quotation } from '../../../../../../@types/interface';
import useSelectedQuotation from '../../hooks/useSelectedQuotation';
import useFinishModal from '../../hooks/useFinishModal';
import useViewRepliesModal from '../../hooks/useViewRepliesModal';
import { ViewRepliesModal } from './components/ViewRepliesModal';

export const AnsweredQuotations = () => {
  const {
    isLoading,
    loadMoreData,
    page,
    handleRowClick,
    handleChangeQuotationState,
    filterType,
    filterValue,
    handleSearch,
    handleDateSearch,
    searchedQuotations,
    setFilterType,
  } = useAnsweredQuotations();

  const { changeQuotationState, isChangingQuotationState } =
    useChangeQuotationState({
      onChangeState: handleChangeQuotationState,
    });
  const { quotationState, finishQuotation } = useQuotation();
  const selectedQuotation = useSelectedQuotation();
  const finishModal = useFinishModal();
  const repliesModal = useViewRepliesModal();

  const [searchParams, setSearchParams] = useSearchParams();
  const viewRepliesModal = useViewRepliesModal();

  const [quotationIdParam, setQuotationIdParam] = useState('');

  const handleGetQuotationByIdParam = useCallback(() => {
    const quotationId = searchParams.get('id');
    if (quotationId) {
      setQuotationIdParam(quotationId);
      setSearchParams({});
      repliesModal.onOpen();
    }
  }, [repliesModal, searchParams, setSearchParams]);

  useEffect(() => {
    handleGetQuotationByIdParam();
  }, [handleGetQuotationByIdParam]);

  const isEmpty = useMemo(
    () => !isLoading && quotationState.answeredQuotationList.length === 0,
    [isLoading, quotationState.answeredQuotationList.length],
  );
  const notFound = useMemo(
    () => filterValue.trim().length > 0 && searchedQuotations.length === 0,
    [filterValue, searchedQuotations.length],
  );

  const handleFinishQuotation = useCallback(async () => {
    const res = await changeQuotationState({
      id: selectedQuotation?.quotation?.id_quotation ?? '',
      action: 'finish',
    });
    if (res) {
      finishQuotation(res);
    }
    finishModal.onClose();
    viewRepliesModal.onClose();
    selectedQuotation.onClear();
  }, [
    finishModal.onClose,
    viewRepliesModal.onClose,
    selectedQuotation?.quotation,
    selectedQuotation.onClear,
    changeQuotationState,
    finishQuotation,
  ]);

  return (
    <>
      <ViewRepliesModal
        isOpen={repliesModal.isOpen}
        onClose={() => {
          repliesModal.onClose();
          selectedQuotation.onClear();
        }}
        reply={selectedQuotation.quotation}
        idParam={quotationIdParam}
      />
      <FinishModal
        isLoading={isChangingQuotationState}
        isOpen={finishModal.isOpen}
        onClose={() => {
          finishModal.onClose();
        }}
        onFinish={handleFinishQuotation}
        quotation={selectedQuotation.quotation}
      />

      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={createdQuotationsIcon} alt="cotações criadas" />
          <p>Propostas recebidas</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as QuotationFilterOptions)
            }
            defaultValue="code"
            variant={
              filterType === 'code' || filterType === 'plate'
                ? 'searchbar'
                : 'date'
            }
            upperCase={filterType === 'plate'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Data',
                value: 'date',
              },
            ]}
          />
        </div>
      </div>
      <QuotationList
        isLoading={isLoading}
        isEmpty={isEmpty}
        notFound={notFound}
        handleRowClick={(quotation: Quotation) => handleRowClick(quotation)}
        onScroll={() => loadMoreData(page)}
        quotationList={
          filterValue.trim().length > 0
            ? searchedQuotations
            : quotationState.answeredQuotationList
        }
      />
    </>
  );
};
