import { Faders, MagnifyingGlass } from '@phosphor-icons/react';
import { CommonInputProps } from '../..';
import styles from './input-search.module.css';
import { Button } from '../../../Button';

interface Props {
  label?: string;
  commonInputAttributes: CommonInputProps;
  name: string;
  error?: string;
  isLoading?: boolean;
  handleIconSearch?: () => void;
  filters?: boolean;
}

export const InputSearch = ({
  commonInputAttributes,
  name,
  error,
  isLoading,
  label,
  filters = false,
  handleIconSearch,
}: Props) => {
  return (
    <>
      <div className={styles['input-control']}>
        {label && <label htmlFor={name}>{label}</label>}
        <div className={styles['input-icon-container']}>
          <input type="search" {...commonInputAttributes} />
          <span className={styles['input-icon']}>
            <button type="button" onClick={handleIconSearch}>
              <MagnifyingGlass size={32} weight="bold" color="#4b4b4b88" />
            </button>
          </span>
        </div>
        {error && <span className={styles['error-text']}>{error}</span>}
      </div>
      {filters && (
        <Button
          variant="icon"
          icon={<Faders size={24} weight="bold" color="#4b4b4b88" />}
          handleClick={() => console.log('')}
          style={{
            transform: 'rotate(180deg)',
            border: '2px solid #4b4b4b88',
            borderRadius: '8px',
            padding: '.5rem',
            width: '2rem',
            height: '2rem',
          }}
        />
      )}
    </>
  );
};
