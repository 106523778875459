import { useMemo, useState } from 'react';
import { PlusSquare, MinusSquare, Timer } from '@phosphor-icons/react';
import { Input } from '../../../../../../../../shared/Input';
import styles from './quotation-description.module.css';
import { Textarea } from '../../../../../../../../shared/Textarea';
import { AnswerQuotation } from '../../../../../../../../@types/interface';
import { HorizontalBannerList } from '../../../../../../../../shared/HorizontalBannerList';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import { useCountdownTimer } from '../../../../../../../../hooks/useCountdownTimer';
import {
  formatDate,
  formatTime,
} from '../../../../../../../../utils/dateFunctions';
import { maskCpfCnpj } from '../../../../../../../../services/helpers/mask';

interface Props {
  answer: AnswerQuotation | null;
}

export const QuotationDescription = ({ answer }: Props) => {
  const [ViewMore, setViewMora] = useState(false);
  const { sizeMobile } = useResponsive();

  const handleDateTime = (value: Date) => {
    const newDate = new Date(value);
    const formattedDate = formatDate(newDate);
    const formattedTime = formatTime(newDate);
    return `${formattedDate} às ${formattedTime}`;
  };

  const quotationNumber = useMemo(() => {
    return answer?.quotation?.QUOTATION ?? '';
  }, [answer?.quotation?.QUOTATION]);

  const createdAt = useMemo(() => {
    if (answer?.quotation?.created_at) {
      return handleDateTime(answer.quotation.created_at);
    }
    return '---';
  }, [answer?.quotation?.created_at]);

  const workshopFantasyName = useMemo(() => {
    return answer?.quotation?.workshop.fantasy_name ?? '';
  }, [answer?.quotation?.workshop.fantasy_name]);

  const workshopDocument = useMemo(() => {
    return answer?.quotation?.workshop.cnpj
      ? maskCpfCnpj(answer.quotation.workshop.cnpj)
      : '---';
  }, [answer?.quotation?.workshop.cnpj]);

  const agent = useMemo(() => {
    return answer?.quotation?.agent ?? null;
  }, [answer?.quotation?.agent]);

  const agentUsername = useMemo(() => {
    return agent?.user?.name ?? '---';
  }, [agent?.user?.name]);

  const paymentMethod = useMemo(() => {
    return answer?.quotation?.type_payment ?? 'Não informado';
  }, [answer?.quotation?.type_payment]);

  const vehicle = useMemo(() => {
    return answer?.quotation?.vehicle ?? null;
  }, [answer?.quotation?.vehicle]);

  const banners = useMemo(() => {
    return answer?.quotation?.banners ?? [];
  }, [answer?.quotation?.banners]);

  const comment = useMemo(() => {
    return answer?.quotation?.comment ?? '';
  }, [answer?.quotation?.comment]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Cotação N° {quotationNumber}</h1>
      {answer && (
        <div className={styles.info}>
          {' '}
          <h3 className={styles.subtitle}>criada em {createdAt}</h3>
        </div>
      )}
      {sizeMobile && (
        <div className={styles.buttonViewMore}>
          {ViewMore ? (
            <PlusSquare
              onClick={() => setViewMora(prev => !prev)}
              size={32}
              color="#007a36"
            />
          ) : (
            <MinusSquare
              onClick={() => setViewMora(prev => !prev)}
              size={32}
              color="#007a36"
            />
          )}
        </div>
      )}

      <ul
        className={`${styles['info-list']} ${
          ViewMore && sizeMobile ? styles.viewMore : ''
        }`}
      >
        <li>
          <Input
            name=""
            label="Origem:"
            value={workshopFantasyName}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
          <Input
            name=""
            label="CPF/CNPJ:"
            value={workshopDocument}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        </li>

        {agent && (
          <Input
            name=""
            label="Vendedor:"
            value={agentUsername}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        )}
        <Input
          name=""
          label="Forma de Pagamento:"
          value={paymentMethod}
          style={{
            border: 'none',
            fontWeight: '600',
            color: '#000000',
          }}
          disabled
        />
        <li>
          <Input
            name=""
            label="Placa:"
            value={vehicle?.license_plate ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
          <Input
            name=""
            label="Ano:"
            value={vehicle?.year ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        </li>
        <li>
          <Input
            name=""
            label="Veículo:"
            value={vehicle?.name ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        </li>
        <li>
          <Input
            name=""
            label="Chassi:"
            value={vehicle?.chassi ?? ''}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        </li>

        {banners.length > 0 && (
          <li>
            <HorizontalBannerList
              bannerList={banners}
              label="Imagens Anexadas:"
            />
          </li>
        )}
        <li>
          <Textarea
            name=""
            label="Observações:"
            value={comment}
            style={{
              border: 'none',
              fontWeight: '600',
              color: '#000000',
            }}
            disabled
          />
        </li>
      </ul>
    </div>
  );
};
