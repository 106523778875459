import { handleHtmlToPdf } from '../handleHtmlToPdf';
import { orderServicePdf } from './orderServicePdf';
import { Container } from '../components/jsx/Container';
import { Section } from '../components/jsx/Section';
import { InfoRow } from '../components/jsx/InfoRow';
import { ServiceTable } from '../components/jsx/ServiceTable';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { maskCpfCnpj, maskPhone } from '../../../services/helpers/mask';
import { dateOffset, formatDate, formatTime } from '../../dateFunctions';
import { getWorkshopData } from '../../workshopData';
import { isWebView } from '../../detectUserAgent';
import { QRCode } from '../../qrcode';

export const OrderServiceReport = () => {
  const orderService = useAppSelector(
    state => state.orderServices.orderService,
  );

  const handleDownloadPdf = () => {
    if (orderService) {
      const html = orderServicePdf({
        ...orderService,
      });
      const pdfProps = {
        html,
        name: 'pdf',
      };
      if (isWebView === '1') {
        window.ReactNativeWebView.postMessage(JSON.stringify(pdfProps));
      } else {
        handleHtmlToPdf(pdfProps.html, pdfProps.name);
      }
    }
  };

  const generateClientSection = () => {
    const items = [
      {
        name: 'Nome',
        value: orderService?.name ?? '-',
      },
      {
        name: 'Telefone',
        value: maskPhone(orderService?.phone ?? '-'),
      },
      {
        name: 'CPF/CNPJ',
        value: maskCpfCnpj(orderService?.user_workshop?.user?.document ?? '-'),
      },
    ];
    return (
      <Section title="Cliente">
        <InfoRow items={items} />
      </Section>
    );
  };
  const generateVehicleSection = () => {
    const km = orderService?.km;

    let kmFormatted = '-';
    if (km !== null && km !== undefined) {
      kmFormatted = km.toLocaleString('pt-br');
    }
    const items = [
      {
        name: 'Modelo',
        value: orderService?.vehicle?.name ?? '-',
      },
      {
        name: 'Placa',
        value: orderService?.vehicle?.license_plate ?? '-',
      },
      {
        name: 'km',
        value: kmFormatted,
      },
    ];
    return (
      <Section title="Veículo">
        <InfoRow items={items} />
      </Section>
    );
  };
  const generateServiceSection = () => {
    let discount = 0;
    let services: { name: string; quantity: number; unit: number }[] = [];
    if (orderService) {
      discount = orderService.discount;
      services = orderService.child_services.map(service => ({
        name: service.name,
        quantity: service.quantity,
        unit: service.total / service.quantity,
      }));
    }
    const initialDate = dateOffset(
      new Date(`${orderService.date}T${orderService.time}`),
    );
    let finishDate = null;
    if (orderService.finish_date && orderService.finish_time)
      finishDate = dateOffset(
        new Date(`${orderService.finish_date}T${orderService.finish_time}`),
      );
    return (
      <Section title="Serviços">
        <InfoRow
          items={[
            {
              name: 'Data de Início',
              value: `${
                orderService && orderService.created_at
                  ? `${formatDate(initialDate)} - ${formatTime(initialDate)}`
                  : '-'
              }`,
            },
            {
              name: 'Data de Término',
              value: `${
                finishDate
                  ? `${formatDate(finishDate)} - ${formatTime(finishDate)}`
                  : '-'
              }`,
            },
          ]}
        />
        <InfoRow
          items={[
            {
              name: 'Defeitos',
              value: orderService?.defect ?? '-',
            },
            {
              name: 'Status',
              value: orderService.finished ? 'Finalizado' : 'Em Andamento',
            },
          ]}
        />
        <ServiceTable discount={discount} services={services} />
      </Section>
    );
  };

  const generateCommentSection = () => {
    const items = [
      {
        name: 'Observações',
        value: orderService?.comment ?? '',
        fullLine: true,
      },
    ];
    return (
      <Section title="Cliente">
        <InfoRow items={items} />
      </Section>
    );
  };

  const generateQRCode = () => {
    if (
      orderService &&
      orderService.checklist &&
      orderService.checklist.banners &&
      orderService.checklist.banners.length > 0
    ) {
      return QRCode(
        `https://oficinas.autocenterapp.com/checklist-report?checklistId=${orderService.checklist_id}`,
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  return (
    <Container
      handleDownloadPdf={handleDownloadPdf}
      workshopName={getWorkshopData()?.fantasy_name ?? 'Oficina Não encontrada'}
      documentName={`Ordem de Serviço - ${
        orderService?.external_id || orderService.OS
      }`}
    >
      <InfoRow
        items={[
          {
            name: 'Data de Emissão',
            value: `${
              orderService && orderService.created_at
                ? `${formatDate(
                    new Date(orderService.created_at),
                  )} - ${formatTime(new Date(orderService.created_at))}`
                : '-'
            }`,
          },
        ]}
      />
      {generateClientSection()}
      {generateVehicleSection()}
      {generateServiceSection()}
      {generateCommentSection()}
      {generateQRCode()}
    </Container>
  );
};
