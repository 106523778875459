import { useRef } from 'react';
import { PageLayout } from '../../../../../../../../shared/PageLayout';
import styles from './answer-quotation-modal.module.css';
import { useAnswerQuotation } from './useAnswerQuotation';
import { AnswerQuotation as AnswerQuotationResponse } from '../../../../../../../../@types/interface';
import { Overlay } from '../../../../../../../../shared/Overlay';

import { QuotationDescription } from '../QuotationDescription';
import { AnswersList } from '../AnswersList';
import { AnswerFeedbackModal } from '../AnswerFeedbackModal';
import { ConfirmAnswerModal } from '../ConfirmAnswerModal';
import { ErrorModal } from '../ErrorModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  answer: AnswerQuotationResponse | null;
  localAnswerId?: string;
}

export const AnswerQuotationModal = ({
  isOpen,
  onClose,
  answer = null,
  localAnswerId = '',
}: Props) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  const {
    control,
    reset,
    status,
    handleResetStatus,
    isFeedbackModalOpen,
    closeErrorModal,
    errorModalMessage,
    formQuotationItems,
    selectedAnswer,
    isConfirmModalOpen,
    handleConfirmModal,
    toggleModal,
    handleCloseModal,
    handleWhatsappMessage,
    addSubItem,
    removeSubItem,
    handleCloseErrorModal,
    trigger,
  } = useAnswerQuotation({ answer, isOpen, localAnswerId, onClose });
  const container = overlayRef.current || document.body;

  const handleModalNavigation = () => {
    onClose();
    handleCloseModal();
  };

  return isOpen ? (
    <>
      <ConfirmAnswerModal
        isOpen={isConfirmModalOpen}
        onClose={() => toggleModal('close')}
        container={container}
        onCancel={() => toggleModal('close')}
        onConfirm={() => {
          handleConfirmModal();
        }}
      />
      <ErrorModal
        container={container}
        isOpen={errorModalMessage.trim().length !== 0}
        onClose={closeErrorModal}
        message={errorModalMessage}
      />
      <AnswerFeedbackModal
        container={container}
        isOpen={isFeedbackModalOpen}
        status={status}
        onClose={() => {
          if (status === 'error') {
            handleCloseErrorModal();
          } else {
            handleResetStatus();
            onClose();
          }
        }}
      />
      <Overlay ref={overlayRef}>
        <PageLayout.Root
          title="Responder cotação"
          sectionStyle={{
            background: 'var(--white)',
          }}
          handleNavigation={handleModalNavigation}
        >
          <div className={styles.content}>
            <div>
              <QuotationDescription answer={selectedAnswer} />
              <AnswersList
                answer={selectedAnswer}
                control={control}
                reset={reset}
                handleAnswer={async () => {
                  const isValid = await trigger();
                  if (isValid) toggleModal('open');
                }}
                formQuotationItems={formQuotationItems}
                handleWhatsappMessage={handleWhatsappMessage}
                addSubItem={addSubItem}
                removeSubItem={removeSubItem}
                onClose={onClose}
              />
            </div>
          </div>
        </PageLayout.Root>
      </Overlay>
    </>
  ) : null;
};
