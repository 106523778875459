import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './finished-quotations.module.css';
import AnsweredQuotationsIcon from '../../../../../../assets/icons/answered-quotations.svg';
import { useFinishedQuotations } from './hooks/useFinishedQuotations';
import { useFilteredAnswers } from '../../hooks/useFilteredAnswers';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { GetMyAnswerFilters } from '../../interface';
import { useAnswerQuotation } from '../../../../../../contexts/answerQuotationContext';
import { ViewAnswerQuotationModal } from '../../../workshop/components/ViewAnswerQuotationModal';
import useViewModal from '../../hooks/useViewModal';
import useSelectedAnswer from '../../hooks/useSelectedAnswer';
import { AnswerList } from './components/QuotationListItem';
import { AnswerQuotation } from '../../../../../../@types/interface';

export const FinishedQuotations = () => {
  const { isLoading, loadMoreData, page, handleRowClick, fetchFinishedAnswer } =
    useFinishedQuotations();

  const {
    filterType,
    filterValue,
    handleDateSearch,
    handleSearch,
    searchedAnswers,
    setFilterType,
  } = useFilteredAnswers({
    option: 'finished',
  });

  const { answerQuotationState } = useAnswerQuotation();
  const [searchParams, setSearchParams] = useSearchParams();

  const viewModal = useViewModal();
  const selectedAnswer = useSelectedAnswer();

  const handleGetQuotationByIdParam = useCallback(async () => {
    const answerId = searchParams.get('id');
    if (answerId) {
      await fetchFinishedAnswer(answerId);
      // setIsViewQuotationModalOpen(true);
      viewModal.onOpen();
    }
    setSearchParams({});
  }, [fetchFinishedAnswer, searchParams, setSearchParams]);

  useEffect(() => {
    handleGetQuotationByIdParam();
  }, [handleGetQuotationByIdParam]);

  const isEmpty = useMemo(
    () =>
      !isLoading && answerQuotationState.finalizedQuotationList.length === 0,
    [answerQuotationState.finalizedQuotationList.length, isLoading],
  );
  const notFound = useMemo(
    () => filterValue.trim().length > 0 && searchedAnswers.length === 0,
    [filterValue, searchedAnswers.length],
  );

  return (
    <>
      <ViewAnswerQuotationModal
        isOpen={viewModal.isOpen}
        onClose={() => {
          // setSelectedAnswerToView(null);
          // setIsViewQuotationModalOpen(false);
          // setSearchedQuotation(null);
          selectedAnswer.onClear();
          viewModal.onClose();
        }}
        answer={selectedAnswer.answer}
      />
      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={AnsweredQuotationsIcon} alt="cotações criadas" />
          <p>Cotações Concluídas</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as GetMyAnswerFilters)
            }
            defaultValue="code"
            variant={filterType === 'date_recieved' ? 'date' : 'searchbar'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Data',
                value: 'date_recieved',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Origem',
                value: 'origin',
              },
            ]}
          />
        </div>
      </div>
      <AnswerList
        answerList={
          filterValue.trim().length > 0
            ? searchedAnswers
            : answerQuotationState.finalizedQuotationList
        }
        handleRowClick={(answer: AnswerQuotation) => handleRowClick(answer)}
        isLoading={isLoading}
        onScroll={() => loadMoreData(page)}
        isEmpty={isEmpty}
        notFound={notFound}
      />
    </>
  );
};
