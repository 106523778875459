import { UseFormReset } from 'react-hook-form';
import {
  getAddressController,
  getWorkshopDataController,
  updateClientCodeController,
  updateUserPasswordController,
  updateWorkshopDataController,
} from '../controller';
import { FormValues } from '../interface';
import { maskCpfCnpj, maskPhone } from '../../../../services/helpers/mask';

export const useRegisterData = () => {
  const fetchApiWorkshop = async (reset: UseFormReset<FormValues>) => {
    const res = await getWorkshopDataController();

    if (res) {
      reset({
        workshopId: res.id_workshop,
        corporateName: res.corporate_name,
        phone: maskPhone(res.phone),
        document: maskCpfCnpj(res.cnpj),
        email: res.email,
        address: {
          city: res.address.city,
          district: res.address.district,
          number: res.address.number,
          street: res.address.street,
          uf: res.address.uf,
          zip: res.address.zip,
        },
        type: res.type,
        fantasyName: res.fantasy_name,
        stateRegistration: res?.state_registration || undefined,
        whatsapp: maskPhone(res.phone),
        code: res?.client_code ?? '',
        quotation_segment: res?.quotation_segment || 'both',
      });
    }
  };

  const fetchAddressByZipCode = async (
    zipCode: string,
    reset: UseFormReset<FormValues>,
    previousFormValues: FormValues,
  ) => {
    const res = await getAddressController(zipCode);
    if (res) {
      reset({
        ...previousFormValues,
        address: {
          // ...previousFormValues.address,
          city: res.localidade,
          district: res.bairro,
          street: res.logradouro,
          uf: res.uf,
          zip: res.cep,
        },
      });
    }
  };

  const updateWorkshopData = async (formValues: FormValues) => {
    const { confirmPassword, password, code, document, ...rest } = formValues;

    await updateWorkshopDataController({
      ...rest,
      cnpj: document,
    });
  };

  const updateWorkshopPassword = async (formValues: FormValues) => {
    const { confirmPassword, password, phone, ...rest } = formValues;

    await updateUserPasswordController({
      newPassword: password,
      confirmPassword,
      phone,
    });
  };

  const updateCode = async (formValues: FormValues) => {
    const { code, ...rest } = formValues;

    await updateClientCodeController(code);
  };

  return {
    fetchApiWorkshop,
    fetchAddressByZipCode,
    updateWorkshopData,
    updateWorkshopPassword,
    // updateCode,
  };
};
