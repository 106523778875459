import { Controller, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import useLoginModal from '../../hooks/useLoginModal';
import { Button } from '../Button';
import { Input } from '../Input';
import { Modal } from '../Modal';
import styles from './login-modal.module.css';
import { authenticateController } from '../../pages/common/LandingPage/Pages/Login/controller';

interface FormValues {
  login: string;
  password: string;
}

export const LoginModal = () => {
  const loginModal = useLoginModal();

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const onSubmit = async (formData: FormValues) => {
    const res = await authenticateController({
      email: formData.login.trim(),
      password: formData.password,
    });

    if (res) {
      localStorage.setItem('@AutoCenter: user', JSON.stringify(res.user));
      localStorage.setItem('@AutoCenter: accessToken', res.accessToken);
      localStorage.setItem('@AutoCenter: refreshToken', res.refreshToken);
      localStorage.setItem('reload', 'true');
    }
    loginModal.onClose();
    window.location.reload();
  };

  const handleSingOut = useCallback(() => {
    window.location.href = '/';
    localStorage.clear();
  }, []);

  const footer = (
    <footer className={styles.footer}>
      <Button
        handleClick={handleSubmit(onSubmit)}
        style={{
          fontSize: '1.25rem',
        }}
      >
        Enviar
      </Button>
    </footer>
  );

  return (
    <Modal
      isOpen={loginModal.isOpen}
      title="Revalide seus Dados"
      footer={footer}
    >
      <form className={styles.container}>
        <Controller
          control={control}
          name="login"
          render={({ field }) => (
            <Input {...field} label="Email, CPF ou CNPJ" />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <Input {...field} type="password" label="Senha" />
          )}
        />
      </form>
      <Button
        handleClick={handleSingOut}
        variant="unstyled"
        style={{
          fontSize: '1.25rem',
          textDecoration: 'underline',
          color: 'var(--primary)',
          marginTop: '0.5rem',
        }}
      >
        Esqueci minha senha
      </Button>
    </Modal>
  );
};
