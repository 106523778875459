import { useCallback, useEffect, useState } from 'react';
import {
  getAnswerQuotationByIdController,
  getMyAnswersController,
} from '../../../controller';
import { AnswerQuotation } from '../../../../../../../@types/interface';
import { useAnswerQuotation } from '../../../../../../../contexts/answerQuotationContext';
import useSelectedAnswer from '../../../hooks/useSelectedAnswer';
import useViewModal from '../../../hooks/useViewModal';

const LIMIT = 80;

export const useFinishedQuotations = () => {
  const selectedAnswer = useSelectedAnswer();
  const viewModal = useViewModal();
  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  // const [selectedAnswerToView, setSelectedAnswerToView] =
  //   useState<AnswerQuotation | null>(null);
  // const [isViewQuotationModalOpen, setIsViewQuotationModalOpen] =
  //   useState(false);

  // const [searchedQuotation, setSearchedQuotation] =
  //   useState<AnswerQuotation | null>(null);

  const { setAnswerQuotationState } = useAnswerQuotation();

  const fetchFinishedAnswer = useCallback(async (id: string) => {
    const res = await getAnswerQuotationByIdController(id);

    if (res) {
      // setSearchedQuotation(res);
      selectedAnswer.onSelect(res);
    }
  }, []);

  const fetchNotAnsweredQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyAnswersController({
          limit: LIMIT,
          page: pageRequest,
          option: 'finished',
        });

        if (res && res.length > 0) {
          setAnswerQuotationState(previous => {
            const newAnswers = res.filter(
              answer =>
                !previous.finalizedQuotationList.some(
                  existingQuotation =>
                    answer.id_answer_quotation ===
                    existingQuotation.id_answer_quotation,
                ),
            );
            return {
              ...previous,
              finalizedQuotationList: [
                ...previous.finalizedQuotationList,
                ...newAnswers,
              ],
            };
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData, setAnswerQuotationState],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchNotAnsweredQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchNotAnsweredQuotations, noMoreData],
  );

  const handleRowClick = (answer: AnswerQuotation) => {
    if (
      !selectedAnswer.answer ||
      selectedAnswer.answer.quotation_id !== answer.quotation_id
    ) {
      selectedAnswer.onSelect(answer);
    } else {
      selectedAnswer.onClear();
    }
  };

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);

  useEffect(() => {
    if (selectedAnswer.answer && viewModal.isOpen) {
      fetchFinishedAnswer(selectedAnswer.answer.id_answer_quotation);
    }
  }, [fetchFinishedAnswer]);

  return {
    page,
    loadMoreData,
    fetchNotAnsweredQuotations,
    // selectedAnswerToView,
    // setSelectedAnswerToView,
    isLoading,
    // isViewQuotationModalOpen,
    // setIsViewQuotationModalOpen,
    handleRowClick,
    // searchedQuotation,
    // setSearchedQuotation,
    fetchFinishedAnswer,
  };
};
