import { useCallback, useEffect, useState } from 'react';
import {
  getAnswerQuotationByIdController,
  getMyAnswersController,
  updateAnswerQuotationWatchingStateController,
} from '../../../controller';
import { AnswerQuotation } from '../../../../../../../@types/interface';
import { useAnswerQuotation } from '../../../../../../../contexts/answerQuotationContext';
import useSelectedAnswer from '../../../hooks/useSelectedAnswer';

const LIMIT = 80;

export const useNotAnsweredQuotations = () => {
  const { setAnswerQuotationState, updateAnswerQuotations } =
    useAnswerQuotation();
  const selectedAnswer = useSelectedAnswer();
  const [page, setPage] = useState(1);

  const [showBlockedQuotationMessage, setShowBlockedQuotationMessage] =
    useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  // const [selectedAnswerToView, setSelectedAnswerToView] =
  //   useState<AnswerQuotation | null>(null);
  const [isAnswerQuotationModalOpen, setIsAnswerQuotationModalOpen] =
    useState(false);

  const [isViewQuotationModalOpen, setIsViewQuotationModalOpen] =
    useState(false);

  const fetchNotAnsweredQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyAnswersController({
          limit: LIMIT,
          page: pageRequest,
          option: 'recieved',
        });

        if (res && res.length > 0) {
          setAnswerQuotationState(previous => {
            const newAnswers = res.filter(
              answer =>
                !previous.receivedQuotationList.some(
                  existingQuotation =>
                    answer.id_answer_quotation ===
                    existingQuotation.id_answer_quotation,
                ),
            );
            return {
              ...previous,
              receivedQuotationList: [
                ...previous.receivedQuotationList,
                ...newAnswers,
              ],
            };
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData, setAnswerQuotationState],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchNotAnsweredQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchNotAnsweredQuotations, noMoreData],
  );

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);

  const handleRowClick = useCallback(
    (answer: AnswerQuotation) => {
      if (
        !selectedAnswer?.answer ||
        selectedAnswer?.answer?.quotation_id !== answer.quotation_id
      ) {
        selectedAnswer.onSelect(answer);
      } else {
        selectedAnswer.onClear();
      }
    },
    [selectedAnswer?.answer],
  );

  const handleShowBlockedQuotationMessage = (option: 'open' | 'close') => {
    if (option === 'open') setShowBlockedQuotationMessage(true);
    else setShowBlockedQuotationMessage(false);
  };

  const handleUnlockQuotationState = async (answerQuotationId: string) => {
    const res = await updateAnswerQuotationWatchingStateController(
      answerQuotationId,
      'off',
    );

    if (res) {
      setAnswerQuotationState(previous => {
        const updatedAnswers = previous.receivedQuotationList.map(answer => {
          if (answer.id_answer_quotation === res.id_answer_quotation) {
            return res;
          }
          return answer;
        });
        return {
          ...previous,
          receivedQuotationList: updatedAnswers,
        };
      });
    }
  };

  const fetchQuotationAnswerById = useCallback(
    async (answerId: string) => {
      const res = await getAnswerQuotationByIdController(answerId);
      if (res) {
        // setSelectedAnswerToView(res);
        selectedAnswer.onSelect(res);
        updateAnswerQuotations(res);
      }
    },
    [selectedAnswer, updateAnswerQuotations],
  );

  return {
    page,
    loadMoreData,
    fetchNotAnsweredQuotations,
    // selectedAnswerToView,
    // setSelectedAnswerToView,
    isLoading,
    // notAnsweredQuotations,
    isAnswerQuotationModalOpen,
    setIsAnswerQuotationModalOpen,
    handleRowClick,
    showBlockedQuotationMessage,
    handleShowBlockedQuotationMessage,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    handleUnlockQuotationState,
    fetchQuotationAnswerById,
  };
};
