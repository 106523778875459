import { ComponentProps } from 'react';
import styles from './table-header.module.css';

type TableHeaderProps = {
  value: string;
  width?: string;
} & ComponentProps<'th'>;

export const TableHeader = ({
  value,
  width = 'auto',
  ...props
}: TableHeaderProps) => {
  return (
    <th
      {...props}
      className={`${styles['table-header']} ${props.className}`}
      style={{
        width,
      }}
    >
      <span>{value}</span>
    </th>
  );
};
