import { useCallback, useMemo } from 'react';
import {
  AnswerQuotation,
  ItemQuotation,
} from '../../../../../../@types/interface';
import { QuotationList } from '../QuotationList';
import styles from './answer-quotation-items.module.css';

interface Props {
  answer: AnswerQuotation | null;
  isOwner?: boolean;
}

export const AnswerQuotationItems = ({
  answer = null,
  isOwner = false,
}: Props) => {
  const quotation = useMemo(
    () => answer?.quotation ?? null,
    [answer?.quotation],
  );

  const fantasyName = useMemo(() => {
    const workshopName = isOwner
      ? answer?.workshop?.fantasy_name
      : answer?.quotation?.workshop?.fantasy_name;

    return workshopName ?? '';
  }, [
    answer?.quotation?.workshop?.fantasy_name,
    answer?.workshop?.fantasy_name,
    isOwner,
  ]);

  const itemsQuotation = useMemo(() => {
    return quotation?.items_quotation ?? [];
  }, [quotation?.items_quotation]);

  const getAnswerItems = useCallback(
    (itemQuotation: ItemQuotation) => {
      return (
        answer?.items_answer_quotation?.filter(
          itemAnswerQuotation =>
            itemAnswerQuotation.item_quotation_id ===
            itemQuotation.id_item_quotation,
        ) || []
      );
    },
    [answer?.items_answer_quotation],
  );

  return (
    <div className={styles.container}>
      <h1>Itens para cotação</h1>
      <QuotationList.Root>
        <div className={styles['quotation-list-content']}>
          <div className={styles['autoshop-info']}>
            <h3>{fantasyName}</h3>
          </div>
          <QuotationList.Header />
          {itemsQuotation.length > 0 &&
            itemsQuotation.map((itemQuotation, index) => {
              const itemsAnswerQuotation = getAnswerItems(itemQuotation);

              return (
                <QuotationList.Item
                  item={itemQuotation}
                  index={index + 1}
                  key={itemQuotation.id_item_quotation}
                  itemsAnswerQuotation={itemsAnswerQuotation}
                  isEmpty={itemsAnswerQuotation.length === 0}
                  answer={answer}
                />
              );
            })}
        </div>
      </QuotationList.Root>
    </div>
  );
};
