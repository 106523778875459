import { useEffect, useState, useRef } from 'react';
import { Socket } from 'socket.io-client';
import { socket } from '../services/api/socket';
import { getUserData } from '../utils/workshopData';

export const useSocket = () => {
  const socketInstanceRef = useRef<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);

  if (!socketInstanceRef.current) {
    socketInstanceRef.current = socket();
  }

  useEffect(() => {
    const socketInstance = socketInstanceRef.current;
    const { id_user } = getUserData();

    if (socketInstance) socketInstance.emit('entry', { user_id: id_user });

    const handleConnect = () => {
      setIsConnected(true);
      console.log('conectado');
    };

    const handleDisconnect = () => {
      setIsConnected(false);
    };

    socketInstance?.on('connect', handleConnect);
    socketInstance?.on('disconnect', handleDisconnect);

    return () => {
      socketInstance?.off('connect', handleConnect);
      socketInstance?.off('disconnect', handleDisconnect);
      socketInstance?.disconnect();
    };
  }, []);

  return {
    isConnected,
    socketInstance: socketInstanceRef.current!,
  };
};
