import { useCallback, useEffect, useMemo, useState } from 'react';
import Lottie from 'lottie-react';
import { Modal } from '../../../../../../../../shared/Modal';
import { StepsController } from '../../../../../../../../shared/StepsController';
import { Button } from '../../../../../../../../shared/Button';
import { Items } from './steps/Items';
import { Companies } from './steps/Companies';
import styles from './edit-quotation.module.css';
import creatingAnimation from '../../../../../../../../assets/roda.json';
import errorAnimation from '../../../../../../../../assets/errorLottie.json';
import { useEditQuotation } from './useEditQuotation';
import { WorkshopList, useSendQuotation } from './useSendQuotation';
import { useQuotationForm } from './useQuotationForm';
import { AlertMessage } from '../../../../../../../../shared/AlertMessage';
import { Quotation } from '../../../../../../../../@types/interface';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onFinish: () => void;
  defaultQuotation?: Quotation | null;
  title: string;
}

export const EditQuotation = ({
  isOpen,
  onClose,
  defaultQuotation = null,
  onFinish,
  title,
}: Props) => {
  const [step, setStep] = useState<
    'items' | 'companies' | 'creating' | 'sending' | 'success' | 'error'
  >('items');
  const [alertMessage, setAlertMessage] = useState('');

  const { sendQuotation, isSending } = useSendQuotation();
  const {
    control,
    getValues,
    imagesList,
    resetFormValues,
    handleRemoveImage,
    handleImageChange,
    handleAddRow,
    handleVehicleFields,
    quotes,
    errors,
    remove,
    trigger,
    reset,
  } = useQuotationForm({ defaultQuotation });

  const { createQuotation, isCreating } = useEditQuotation({
    defaultQuotation,
    shouldSearchQuotation: isOpen,
    reset,
  });

  const { sizeMobile } = useResponsive();
  const [workshopList, setWorkshopList] = useState<WorkshopList[]>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const selectedWorkshops = useMemo(
    () => workshopList.filter(workshop => workshop.selected),
    [workshopList],
  );

  useEffect(() => {
    if (defaultQuotation && defaultQuotation.answers_quotation) {
      const previousWorkshopList = defaultQuotation.answers_quotation.map(
        answer => ({
          option: {
            name: answer.workshop.fantasy_name,
            value: answer.workshop.id_workshop,
          },
          selected: true,
        }),
      );
      setWorkshopList(previousWorkshopList);
    }
  }, [defaultQuotation]);

  const handleStep = () => {
    if (step === 'items') {
      return (
        <Items
          control={control}
          handleAddRow={handleAddRow}
          handleImageChange={handleImageChange}
          handleRemoveImage={handleRemoveImage}
          quotes={quotes}
          remove={remove}
          imagesList={imagesList}
          handleVehicleFields={handleVehicleFields}
        />
      );
    }
    if (step === 'companies') {
      return (
        <Companies
          setWorkshopList={setWorkshopList}
          workshopList={workshopList}
        />
      );
    }
    if (step === 'creating') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} />
        </div>
      );
    }
    if (step === 'sending') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} />
        </div>
      );
    }
    if (step === 'success') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} loop={false} />
        </div>
      );
    }
    if (step === 'error') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={errorAnimation} />
        </div>
      );
    }
  };

  const handleCreateQuotation = async () => {
    const {
      quotes: items,
      vehicleId,
      comment,
      // hours,
      paymentMethod,
    } = getValues();
    const res = await createQuotation({
      items,
      vehicleId,
      comment: comment ?? '',
      imagesList,
      // hours,
      type_payment: paymentMethod,
    });
    if (res) {
      // finish previous quotation after creation
      await onFinish();
      return res;
    }
    setStep('error');
  };

  const handleSendQuotation = async (quotationId: string) => {
    const res = await sendQuotation({
      quotationId,
      workshopList,
    });
    if (res) {
      setStep('success');
      handleClose();
    } else {
      setStep('error');
    }
  };

  const onSubmit = async () => {
    setStep('creating');
    const res = await handleCreateQuotation();
    if (res) {
      setStep('sending');
      await handleSendQuotation(res.id_quotation);
    }
  };

  const handleClose = () => {
    setStep('items');
    resetFormValues();
    onClose();
  };

  const handleModalTitle = useMemo(() => {
    if (step === 'items' || step === 'companies') return title;
    if (step === 'sending') return 'Enviando Cotação';
    if (step === 'creating') return 'Criando Cotação';
    if (step === 'success') return 'Cotação Criada';
    return 'Falha ao criar cotação';
  }, [step, title]);

  const changeStep = useCallback(async () => {
    const isValid = await trigger();
    if (isValid && !isEdit) {
      setStep('companies');
    } else if (errors.quotes) {
      setAlertMessage(
        'Verifique suas Verifique os itens da cotação, ela não pode ser uma cotação vazia de itens!',
      );
    } else if (isEdit) {
      setAlertMessage('Salve suas alterações');
    }
  }, [errors.quotes, isEdit, trigger]);

  const handleNextStepButton = useCallback(() => {
    if (step === 'items') {
      return (
        <div className={styles.wrapButtonFooter}>
          <Button
            variant="register"
            style={{
              padding: '.5rem 2.5rem',
            }}
            handleClick={changeStep}
          >
            Próximo
          </Button>
        </div>
      );
    }

    return (
      <div className={styles.wrapButtonFooter}>
        {selectedWorkshops.length > 0 ? (
          <Button
            variant="register"
            style={{
              padding: '.5rem 2.5rem',
            }}
            handleClick={() => {
              setIsConfirmModalOpen(true);
            }}
          >
            Enviar
          </Button>
        ) : null}
      </div>
    );
  }, [changeStep, selectedWorkshops.length, step]);

  function handleUpdateNextStep() {
    if (step !== 'items' && step !== 'companies') return null;
    return handleNextStepButton();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={handleModalTitle}
      size={sizeMobile ? 'full' : 'large'}
      footer={handleUpdateNextStep()}
    >
      {(step === 'items' || step === 'companies') && (
        <StepsController.Root>
          <StepsController.Item
            label="1. Itens"
            selected={step === 'items'}
            onClick={() => setStep('items')}
          />
          <StepsController.Item
            label="2. Fornecedores"
            selected={step === 'companies'}
            onClick={() => changeStep()}
          />
        </StepsController.Root>
      )}
      <AlertMessage.Root
        isOpen={alertMessage.length !== 0}
        onClose={() => setAlertMessage('')}
      >
        <AlertMessage.Body>{alertMessage}</AlertMessage.Body>
      </AlertMessage.Root>

      <AlertMessage.Root
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        title="Confirmar Envio"
        footer={
          <AlertMessage.Footer>
            <AlertMessage.CancelButton
              onClick={() => setIsConfirmModalOpen(false)}
            >
              Revisar
            </AlertMessage.CancelButton>
            <AlertMessage.ConfirmButton
              onClick={() => {
                setIsConfirmModalOpen(false);
                onSubmit();
              }}
            >
              Enviar Cotação
            </AlertMessage.ConfirmButton>
          </AlertMessage.Footer>
        }
      >
        <AlertMessage.Body>
          Tem certeza que deseja enviar a cotação?
        </AlertMessage.Body>
      </AlertMessage.Root>

      {handleStep()}
    </Modal>
  );
};
