import api from '../../../../../services/api/api';
import { LoginInput, User } from './interface';

export const authenticate = async (props: LoginInput) => {
  const { data } = await api.post<User>('/auth/workshop', {
    ...props,
  });

  return data;
};

export const visitorAuthenticate = async () => {
  const { data } = await api.post<User>('/auth/workshop', {
    email: 'visitante@hotmail.com',
    password: '123456',
  });

  return data;
};
