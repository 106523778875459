import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FileText } from 'phosphor-react';
import styles from './quotation-description.module.css';
import { Quotation } from '../../../../../../../../@types/interface';
import { Button } from '../../../../../../../../shared/Button';
import { useChangeQuotationState } from '../../../../hooks/useChangeQuotationState';
import { removeAnsweredQuotation } from '../../../../../../../../features/quotation/quotation-reply-slice';
import { ConfirmFinishModal } from '../ConfirmFinishModal';
import { DescriptionLoading } from '../DescriptionLoading';
import {
  formatDate,
  formatTime,
} from '../../../../../../../../utils/dateFunctions';
import { BoxInfo } from '../../../../components/BoxInfo';
import useResumeModal from '../../../../hooks/useResumeModal';
import { useQuotation } from '../../../../../../../../contexts/quotationContext';

interface Props {
  quotation: Quotation | null;
  onClose: () => void;
  container: Element | DocumentFragment;
  isLoading: boolean;
}

export const QuotationDescription = ({
  quotation,
  onClose,
  container,
  isLoading,
}: Props) => {
  const dispatch = useDispatch();
  const resumeModal = useResumeModal();
  const { finishQuotation } = useQuotation();

  const {
    changeQuotationState,
    isChangingQuotationModalOpen,
    handleFinishModal,
  } = useChangeQuotationState({
    onChangeState: () => {
      dispatch(removeAnsweredQuotation(quotation?.id_quotation || ''));
      onClose();
    },
  });

  const handleDateTime = (value: Date) => {
    const newDate = new Date(value);
    const formattedDate = formatDate(newDate);
    const formattedTime = formatTime(newDate);
    return `${formattedDate} às ${formattedTime}`;
  };

  const onFinishQuotation = useCallback(async () => {
    const res = await changeQuotationState({
      id: quotation?.id_quotation ?? '',
      action: 'finish',
    });
    if (res) {
      finishQuotation(res);
    }
    handleFinishModal('close');
    onClose();
  }, [
    changeQuotationState,
    finishQuotation,
    handleFinishModal,
    onClose,
    quotation?.id_quotation,
  ]);

  return (
    <>
      <ConfirmFinishModal
        isOpen={isChangingQuotationModalOpen}
        onClose={() => {
          handleFinishModal('close');
        }}
        onFinish={onFinishQuotation}
        container={container}
      />
      <div className={styles.container}>
        {isLoading ? (
          <DescriptionLoading />
        ) : (
          <>
            <h1 className={styles.title}>
              Cotação N° {quotation?.QUOTATION || ''}
            </h1>
            {quotation && (
              <div className={styles.info}>
                {' '}
                <h3 className={styles.subtitle}>
                  criada em {handleDateTime(quotation.created_at)}
                </h3>
              </div>
            )}

            {/* <ul
              className={`${styles['info-list']} ${
                ViewMore && sizeMobile ? styles.viewMore : ''
              }`}
            >
              <li>
                <Input
                  name=""
                  label="Placa:"
                  value={quotation?.vehicle?.license_plate ?? ''}
                  disabled
                  style={inputStyles}
                />
                <Input
                  name=""
                  label="Ano:"
                  value={quotation?.vehicle?.year ?? ''}
                  disabled
                  style={inputStyles}
                />
              </li>
              <li>
                <Input
                  name=""
                  label="Veículo:"
                  value={quotation?.vehicle?.name ?? ''}
                  disabled
                  style={inputStyles}
                />
              </li>
              <li>
                <Input
                  name=""
                  label="Chassi:"
                  value={quotation?.vehicle?.chassi ?? ''}
                  disabled
                  style={inputStyles}
                />
              </li>
              <li>
                <Textarea
                  name=""
                  label="Observações:"
                  value={quotation?.comment ?? ''}
                  disabled
                  style={{
                    border: 'none',
                    fontWeight: '500',
                    color: '#000000',
                  }}
                />
              </li>
              {quotation?.banners && quotation.banners.length > 0 && (
                <li className={styles['images-container']}>
                  <HorizontalBannerList
                    bannerList={quotation.banners}
                    label="Imagens Anexadas:"
                  />
                </li>
              )}
            </ul> */}
            <BoxInfo quotation={quotation} />
            <div className={styles.actions}>
              <Button
                variant="default"
                theme="delete"
                handleClick={() => handleFinishModal('open')}
                style={{ textAlign: 'center' }}
              >
                Finalizar cotação
              </Button>
              <Button handleClick={() => resumeModal.onOpen()}>
                <FileText size={32} />
                Resumo de Propostas
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
