import { PlusCircle } from 'phosphor-react';
import { useCallback, useEffect, useState } from 'react';
import { PageLayout } from '../../../shared/PageLayout';
import { Button } from '../../../shared/Button';
import styles from './agent-management.module.css';
import { ListTable } from '../../../shared/ListTable';
import { AgentsListItem } from './components/AgentsListItem';
import { CreateAgentModal } from './components/CreateAgentModal';
import { getAgentController } from './controller';
import { getObjectFromLocal } from '../../../utils/localStorage';
import { AgentResponse } from './interface';

export const AgentManagement = () => {
  const [isAgentCreateModalOpen, setIsAgentCreateModalOpen] = useState(false);
  const [listAgent, setListAgent] = useState<AgentResponse[]>([]);
  const [refreshListAgent, setRefreshListAgent] = useState(false);
  const [idWorshop] = useState(
    getObjectFromLocal('@AutoCenter: user').workshop.id_workshop,
  );
  const handleListAgent = useCallback(
    () =>
      listAgent &&
      listAgent?.map(agent => (
        <AgentsListItem key={agent.id_agent} data={agent} />
      )),
    [listAgent],
  );

  const fetchAgentList = useCallback(async () => {
    const res = await getAgentController();
    if (res) {
      setListAgent(res);
    }
  }, []);

  useEffect(() => {
    if (refreshListAgent) {
      setRefreshListAgent(false);
      fetchAgentList();
    }
  }, [fetchAgentList, refreshListAgent]);

  useEffect(() => {
    fetchAgentList();
  }, []);

  return (
    <PageLayout.Root title="cadastro de Vendedores">
      <CreateAgentModal
        isOpen={isAgentCreateModalOpen}
        idWorshop={idWorshop}
        onClose={() => {
          setIsAgentCreateModalOpen(false);
          setRefreshListAgent(!refreshListAgent);
        }}
        onCreate={(newAgent: AgentResponse) => {
          setListAgent(previous => [newAgent, ...previous]);
        }}
      />
      <div className={styles['description-heading']}>
        <Button
          handleClick={() => setIsAgentCreateModalOpen(true)}
          style={{
            padding: '.8rem 1.2rem .8rem 1rem',
            marginRight: '-8px',
          }}
        >
          <PlusCircle size={32} />
          Adicionar
        </Button>
      </div>
      <h1 className={styles['list-title']}>Vendedores Cadastrados</h1>
      <ListTable.Root>
        <ListTable.Head
          style={{
            gridTemplateColumns: '.35fr repeat(3, 1fr) .15fr',
          }}
        >
          <span />
          <span>Nome</span>
          <span>CPF</span>
          <span>Data de Cadastro</span>
          <span />
        </ListTable.Head>
        <ListTable.Body onScroll={() => console.log('')}>
          {handleListAgent()}
        </ListTable.Body>
      </ListTable.Root>
    </PageLayout.Root>
  );
};
