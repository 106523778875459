import { BrowserRouter } from 'react-router-dom';
import { useEffect, Suspense, lazy, useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';
import { getWorkshopData } from '../utils/workshopData';
import { ReportRoutes } from './reports/routes';
import { CommonRoutes } from './common/routes';
import Fallback from '../shared/Fallback/Fallback';
import { NotificationProvider } from '../contexts/notificationContext';

export const visitorId = 'f746c180-d2a2-47d3-9011-81b3b923beff';

const WorkshopRoutes = lazy(() => import('./workshop/routes'));
const FleetRoutes = lazy(() => import('./fleet/routes'));
const GasStationRoutes = lazy(() => import('./gasStation/routes'));

const Routes = () => {
  useEffect(() => {
    Object.keys(Cookies.get()).forEach(cookieName => {
      Cookies.remove(cookieName);
    });
  }, []);

  const accessType = useMemo(() => {
    const workshopData = getWorkshopData();
    return workshopData && workshopData.type
      ? workshopData.type.toLocaleUpperCase()
      : '';
  }, []);

  const handleAccessType = useCallback(() => {
    switch (accessType) {
      case 'GAS_STATION':
        return (
          <>
            <ReportRoutes />
            <Suspense fallback={<Fallback />}>
              <GasStationRoutes />
            </Suspense>
            <CommonRoutes />
          </>
        );
      case 'FLEET':
        return (
          <>
            <ReportRoutes />
            <Suspense fallback={<Fallback />}>
              <FleetRoutes />
            </Suspense>
            <CommonRoutes />
          </>
        );
      default:
        return (
          <>
            <ReportRoutes />
            <Suspense fallback={<Fallback />}>
              <WorkshopRoutes />
            </Suspense>
            <CommonRoutes />
          </>
        );
    }
  }, [accessType]);

  return (
    <BrowserRouter>
      <NotificationProvider>{handleAccessType()}</NotificationProvider>
    </BrowserRouter>
  );
};

export default Routes;
