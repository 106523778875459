import { Navigate, Route, Routes as Switch } from 'react-router-dom';
import ContactLandingPage from '../../pages/common/LandingPage/Pages/Contact';
import LoginLandingPage from '../../pages/common/LandingPage/Pages/Login/Login';
import ServicesLandingPage from '../../pages/common/LandingPage/Pages/Services';
import RegisterLandingPage from '../../pages/common/LandingPage/Pages/Register';
import { LandingPage } from '../../pages/common/LandingPage/Pages';
import About from '../../pages/common/LandingPage/Pages/AboutUs';
import { QuotationNotification } from '../../pages/common/QuotationNofication';
import PrivateRoute from '../routesPrivate';
import { AgentManagement } from '../../pages/common/AgentManagement';
import { Register } from '../../pages/common/Register';
import { WorkshopQuotation } from '../../pages/common/Quotation/workshop';
import { AutoshopQuotation } from '../../pages/common/Quotation/autoshop';
import { Version } from '../../version';
import { AnswerQuotationProvider } from '../../contexts/answerQuotationContext';
import { QuotationProvider } from '../../contexts/quotationContext';

export const CommonRoutes = () => {
  return (
    <Switch>
      <Route path="/" element={<LandingPage />} />|
      <Route path="/version" element={<Version />} />|
      <Route path="/landing-page" element={<Navigate to="/" />} />
      <Route path="/page-services" element={<ServicesLandingPage />} />
      <Route path="/page-contact" element={<ContactLandingPage />} />
      <Route path="/page-login" element={<LoginLandingPage />} />
      <Route path="/page-register" element={<RegisterLandingPage />} />
      <Route path="/page-aboutUs" element={<About />} />
      <Route
        path="/new-quotation"
        element={
          <PrivateRoute>
            <QuotationProvider>
              <AnswerQuotationProvider>
                <WorkshopQuotation />
              </AnswerQuotationProvider>
            </QuotationProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/answer-quotation"
        element={
          <PrivateRoute>
            <QuotationProvider>
              <AnswerQuotationProvider>
                <AutoshopQuotation />
              </AnswerQuotationProvider>
            </QuotationProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/quotation-notification"
        element={
          <PrivateRoute>
            <QuotationNotification />
          </PrivateRoute>
        }
      />
      <Route
        path="/agent-management"
        element={
          <PrivateRoute>
            <AgentManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PrivateRoute>
            <Register />
          </PrivateRoute>
        }
      />
    </Switch>
  );
};
